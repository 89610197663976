<template>
  <section>
    <b-overlay
      v-if="loadingShow"
      class="mt-5"
      :show="loadingShow"
      rounded="lg"
      opacity="0.6"
    />
    <b-overlay :show="loadingAtualizationShow" rounded="lg" opacity="0.6">
      <b-row v-if="!loadingShow" class="custom-row-gutter">
        <b-col lg="12" sm="12">
          <cabecario :data="data.cabecario" v-on="$listeners" />
        </b-col>
        <b-col lg="12" sm="12">
          <card-resumos
            :FaseSelecionada="faseSelecionada"
            :dados="planejaResumos"
            :key="keyAtualizacaoCards"
          />
        </b-col>

        <b-col xl="4" lg="12" md="12" class="custom-col-gutter">
          <ciclo-andamento
            :FaseSelecionada="faseSelecionada"
            :key="keyAtualizacaoCards"
          />
          <b-card no-body>
            <div class="altura_grafico_taxa_resposta">
              <taxa-resposta-equipe
                :dados="taxaRespostaEquipe"
                :FaseSelecionada="faseSelecionada"
                :key="keyAtualizacaoCards"
              />
            </div>
          </b-card>
          <b-card no-body>
            <div class="altura_grafico_fases_realizadas">
              <titulo-generico titulo="Fases Realizadas" />
              <fases-realizadas
                :Fases="fasesDaEmpresa"
                :FaseSelecionada="faseSelecionada"
                @AtualizarCicloSelecionado="
                  atualizaFaseSelecionadaDoDashboard
                "
              />
            </div>
          </b-card>
        </b-col>

        <b-col xl="8" lg="12" md="12" class="custom-col-gutter">
          <b-card no-body>
            <div class="altura_grafico_classificacao">
              <titulo-generico titulo="Classificação de Saúde dos Colaboradores" :subtitulo="'Atualizado em '+dataCicloTempoReal"/>
              <classificacao-saude
                :FaseSelecionada="faseSelecionada"
                :DataCicloTempoReal="dataCicloTempoReal"
                :key="keyAtualizacaoCards"
                :dados="classificacaoSaude"
              />
            </div>

          </b-card>

          <b-card no-body>
            <div class="altura_grafico_doencas_cronicas">
              <titulo-generico titulo="Doenças Crônicas"/>
              <doencas-cronicas-grafico
                :dados="doencasCronicas"
                v-if="dadosCarregados"
                :FaseSelecionada="faseSelecionada"
                :DataCicloTempoReal="dataCicloTempoReal"
                :key="keyAtualizacaoCards"
              />
            </div>
          </b-card>
          <b-card no-body>
            <div class="altura_grafico_mudanca_habito">
              <titulo-generico titulo="Prontidão para Mudanças de Hábitos" :subtitulo="'Atualizado em '+dataCicloTempoReal" />
              <mudancas-habitos
                :FaseSelecionada="faseSelecionada"
                :DataCicloTempoReal="dataCicloTempoReal"
                :dados="mudancasHabitos"
                :key="keyAtualizacaoCards"
              />
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
  </section>
</template>

<script>
import { BRow, BCol, BOverlay,BCard } from "bootstrap-vue";
import CicloAndamento from "@/views/components/custom/dashboard-planeja-saude/CicloAndamento.vue";
import TituloGenerico from "@/views/components/custom/dashboard-saude-mental/TituloGenerico.vue";
import DoencasCronicasGrafico from "@/views/components/custom/dashboard-planeja-saude/DoencasCronicasGrafico.vue";
import TaxaRespostaEquipe from "@/views/components/custom/dashboard-planeja-saude/TaxaRespostaEquipe.vue";
import FasesRealizadas from "@/views/components/custom/dashboard-planeja-saude/FasesRealizadas.vue";
import CiclosRealizados from "@/views/components/custom/dashboard-planeja-saude/CiclosRealizados.vue";
import CardResumos from "@/views/components/custom/dashboard-planeja-saude/CardResumos.vue";
import ClassificacaoSaude from "@/views/components/custom/dashboard-planeja-saude/ClassificacaoSaude.vue";
import MudancasHabitos from "@/views/components/custom/dashboard-planeja-saude/MudancasDeHabitos.vue";
import Cabecario from "@/views/pages/dashboard/Cabecario.vue";
import { modalGenericModel } from "@/libs/sweetalerts";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BOverlay,
    CicloAndamento,
    TaxaRespostaEquipe,
    CiclosRealizados,
    ClassificacaoSaude,
    FasesRealizadas,
    MudancasHabitos,
    CardResumos,
    DoencasCronicasGrafico,
    Cabecario,
    TituloGenerico

  },

  props: {
    Empresa: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      fasesDaEmpresa: [],
      faseSelecionada: null,
      dadosCardFase: {
        allFases: [],
        selectedFase: null,
      },
      loadingShow: true,
      dataCicloTempoReal: null,
      doencasCronicasTotal:  [],
      doencasCronicasAvc: [],
      doencasCronicasArtriteRealmatismo: [],
      doencasCronicasAsmaBronquite: [],
      doencasCronicasColesterol: [],
      doencasCronicasCancer: [],
      doencasCronicasDepressao: [],
      doencasCronicasDiabetes: [],
      keyAtualizacaoCards: 0,
      doencasCronicasHipertensao: [],
      mudancasHabitos: [],
      planejaResumos: [],
      classificacaoSaude: [],
      taxaRespostaEquipe: [],
      doencasCronicas: [
        {
          descricao: "Total",
          dados: [

          ]
        },
        {
          descricao: "avc",
          dados: []
        },
        {
          descricao: "artrite_reumatismo",
          dados: []
        },
        {
          descricao: "asma_bronquite",
          dados: []
        },
        {
          descricao: "colesterol_triglicerides",
          dados: []
        },
        {
          descricao: "cancer",
          dados: []
        },
        {
          descricao: "depressao",
          dados: []
        },
        {
          descricao: "diabetes",
          dados: []
        },
        {
          descricao: "hipertensao",
          dados: []
        }
      ],
      loadingAtualizationShow: false,
      dadosCarregados: false,
      data: {
        cicloAndamento: {},
        taxaRespostaEquipe: {},
        ciclosRealizados: {},
        escalaRespostas: {},
        estatisticasDepressao: {},
        areaDificuldade: {},
        cabecario: {},
      },
    };
  },


  async mounted() {
    await this.validaExistenciaCiclo();
  },

  methods: {
    async validaExistenciaCiclo() {
      let rota = this.$helpers.preparaRotaComParametros(
        this.$api.DashboardPlanejaCiclos,
        "{idEmpresa}",
        this.Empresa.id_empresa
      );
      await this.$http
        .get(rota)
        .then(async (res) => {
          if (res.status == 204) {
            modalGenericModel(
              'Resultados não encontrados',
              'A empresa buscada não possui resultados para este módulo',
              require('@/assets/custom-icons/cora-icons/warning.png'),
              'Entendi'
            ).then(() => {
              this.$emit("update:validadorCiclo", false);
            });
          } else {
            this.preencheDados(res.data);

            this.dadosCardFase = {
              allFases: res.data
            }

            await this.buscaDados().then(async () => {
              this.loadingShow = false
            })
          }
        })
        .catch((_error) => {
          this.$router.push({ name: "error-404" });
        });
    },

    async buscaDados(){
      await Promise.all([
        this.buscaDadosCard(this.$api.DashboardPlanejaDoencasCronicasTotal, "doencasCronicasTotal"),
        this.buscaDadosCard(this.$api.DashboardPlanejaDoencasCronicas, "doencasCronicasAvc", "AVC (Acidente Vascular Cerebral)"),
        this.buscaDadosCard(this.$api.DashboardPlanejaDoencasCronicas, "doencasCronicasArtriteRealmatismo", "Artrite ou Reumatismo"),
        this.buscaDadosCard(this.$api.DashboardPlanejaDoencasCronicas, "doencasCronicasAsmaBronquite", "Asma ou Bronquite"),
        this.buscaDadosCard(this.$api.DashboardPlanejaDoencasCronicas, "doencasCronicasColesterol", "Colesterol ou Triglicérides Alto"),
        this.buscaDadosCard(this.$api.DashboardPlanejaDoencasCronicas, "doencasCronicasCancer", "Câncer"),
        this.buscaDadosCard(this.$api.DashboardPlanejaDoencasCronicas, "doencasCronicasDepressao", "Depressão"),
        this.buscaDadosCard(this.$api.DashboardPlanejaDoencasCronicas, "doencasCronicasDiabetes", "Diabetes"),
        this.buscaDadosCard(this.$api.DashboardPlanejaDoencasCronicas, "doencasCronicasHipertensao", "Hipertensão Arterial"),
        this.buscaDadosCard(this.$api.DashboardPlanejaHabitos, "mudancasHabitos"),
        this.buscaDadosCard(this.$api.DashboardPlanejaResumo, "planejaResumos"),
        this.buscaDadosCard(this.$api.DashboardPlanejaClassificacaoSaude, "classificacaoSaude"),
        this.buscaDadosCard(this.$api.DashboardPlanejaCardTaxaRespostaEquipe, "taxaRespostaEquipe"),
      ]);

      //Card de Doencas Cronicas
      this.doencasCronicas[0].dados = this.doencasCronicasTotal
      this.doencasCronicas[1].dados = this.doencasCronicasAvc
      this.doencasCronicas[2].dados = this.doencasCronicasArtriteRealmatismo
      this.doencasCronicas[3].dados = this.doencasCronicasAsmaBronquite
      this.doencasCronicas[4].dados = this.doencasCronicasColesterol
      this.doencasCronicas[5].dados = this.doencasCronicasCancer
      this.doencasCronicas[6].dados = this.doencasCronicasDepressao
      this.doencasCronicas[7].dados = this.doencasCronicasDiabetes
      this.doencasCronicas[8].dados = this.doencasCronicasHipertensao

      this.dadosCarregados = true;

    },

    async buscaDadosCard(apiLink, stringPropDados, doenca = null){
      const params = {
        idEmpresa: this.Empresa.id_empresa,
        idFase: this.faseSelecionada.id_fase,
        nomeDoenca: doenca
      }
      await this.$http
      .get(apiLink, { params })
      .then((res) => {
        if (res.status == 204) {
          this.$set(this, stringPropDados, [])
        } else {
          this.$set(this, stringPropDados, res.data)
        }
      })
      .catch(() => {
        this.$router.push({ name: "error-404" });
      });
    },

    preencheDados(data) {
      this.fasesDaEmpresa = data;
      this.faseSelecionada = this.fasesDaEmpresa[0];
      this.faseSelecionada.id = 1;
      this.dataCicloTempoReal = this.progressoFase(
        this.faseSelecionada.inicio,
        this.faseSelecionada.termino
      );
      this.data.cabecario = this.Empresa;
      this.loadingShow = true;
    },

    parseDate(input) {
      let parts = input.match(/(\d+)/g);
      return new Date(parts[0], parts[1] - 1, parts[2]);
    },

    formatarData(data) {
      const objetoData = data ? this.parseDate(data) : new Date();
      return objetoData.toLocaleDateString("pt-BR", { timeZone: "UTC" });
    },

    progressoFase(dataInicio, dataFim) {
      let objetoDataInicio = this.parseDate(dataInicio);
      let objetoDataFim = this.parseDate(dataFim);
      let objetoDataNow = new Date();
      if (objetoDataInicio > objetoDataNow) {
        return this.formatarData() + " - Ciclo ainda não iniciado";
      } else if (
        objetoDataInicio < objetoDataNow &&
        objetoDataFim > objetoDataNow
      ) {
        return this.formatarData();
      } else if (objetoDataFim < objetoDataNow) {
        return this.formatarData(dataFim);
      }
    },

    async atualizaFaseSelecionadaDoDashboard(objectFase){
      this.loadingAtualizationShow = true
      this.dadosCardFase.selectedFase = objectFase
      this.faseSelecionada = objectFase;
      await this.buscaDados({
        idFase: this.dadosCardFase.selectedFase.id_fase,
        idEmpresa: this.dadosCardFase.selectedFase.id_empresa,
      });
      this.keyAtualizacaoCards++
      this.loadingAtualizationShow = false
    },

    buscarDadosCardCiclosPorCicloSelecionado(cicloSelecionado) {
      this.faseSelecionada = cicloSelecionado;
    },
  },
};
</script>

<style lang="scss">
.fonte14 {
  font-size: 14px;
}
.fonte18 {
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
}
.clicavel {
  cursor: pointer;
}
.custom-col-gutter {
  padding-left: 7.5px;
  padding-right: 7.5px;
}

.custom-row-gutter{
  margin-left: -7.5px;
  margin-right: -7.5px;
}
.altura_grafico_doencas_cronicas{
  height: 413px;
}


.altura_grafico_classificacao{
  height: 375px;
}

.altura_grafico_mudanca_habito{
  height: 500px;
}

@media(max-width: 1200px) {
    .altura_grafico_mudanca_habito{
      height: auto;
    }

    .altura_grafico_classificacao{
      height: auto;
    }
}

@media  (max-width: 991px) {
  .altura_grafico_doencas_cronicas{
    height: 570px;
  }
}
</style>
