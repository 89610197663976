<template>
  <main>
    <b-overlay :show="loading" rounded="lg" opacity="1">
      <div v-if="validadorBotao == false" class="card p-2">
        <b-col lg="12" md="12" sm="12">
          <h1>Painel por CNPJ</h1>
          <p>
            Busque uma empresa pela Razão Social ou CNPJ para visualizar o
            painel do planeja saúde
          </p>
        </b-col>
        <b-col
          lg="12"
          md="12"
          sm="12"
          class="mt-1 pb-1 d-flex flex-row h-100 responsive"
        >
          <h3 class="alinhamento_embaixo">Empresa</h3>
          <v-select
            class="w-100 responsive_espacamento"
            v-model="empresaSelecionada"
            :options="empresas"
            label="razaoCnpjEmpresa"
            placeholder="Razão Social ou CNPJ"
            :clearable="false"
            required
          >
            <span slot="no-options">Nenhuma empresa encontrada.</span>
          </v-select>
          <b-button
            id="botao-redirecionar-dashboard"
            variant="custom"
            type="submit"
            :disabled="empresaSelecionada == null ? true : false"
            @click.prevent="toggleValue()"
          >
            <span class="align-middle">Selecionar</span>
          </b-button>
        </b-col>
      </div>
      <div v-else>
        <dashboardEmpresaSelecionada
          :Empresa="empresaSelecionada"
          v-bind:validadorCiclo.sync="validadorBotao"
          @redirecionamento-filtragem="toggleValue"
        ></dashboardEmpresaSelecionada>
      </div>
    </b-overlay>
  </main>
</template>

<script>

import { BRow, BCol, BButton,BOverlay, } from "bootstrap-vue";
import { required } from "@validations";
import vSelect from "vue-select";
import DashboardEmpresaSelecionada from "@/views/pages/dashboard/planeja-saude/DashboardPlanejaEmpresaSelecionada.vue";
import { modalGenericFillableErrorWithRedirectHome } from "@/libs/sweetalerts";

export default {
  components: {
    BRow,
    BOverlay,
    BCol,
    BButton,
    vSelect,
    DashboardEmpresaSelecionada,
  },

  data() {
    return {
      loading: true,
      required,
      validadorBotao: false,
      empresaSelecionada: null,
      empresas: [],
      modulo: "Planeja Saúde",
    };
  },

  beforeMount() {
    this.preencheSelect(this.modulo);
  },

  methods: {
    verificaEmpresaJaSelecionada(empresas) {
      this.empresaSelecionada = empresas.find(
        (item) => item.id_empresa == this.$route.params.idEmpresa
      );
      this.validadorBotao = !this.validadorBotao;
    },
    preencheSelect(modulo) {
      let rota = this.$helpers.preparaRotaComParametros(
        this.$api.EmpresaBuscarDashboard,
        "{modulo}",
        modulo
      );
      this.$http
        .get(rota)
        .then((res) => {
          this.empresas = res.data ? res.data : [];
          if (this.$route.params.idEmpresa) {
            this.verificaEmpresaJaSelecionada(this.empresas);
          }
          this.loading = false;
        })
        .catch(() => {
          modalGenericFillableErrorWithRedirectHome(
            'O módulo atual não foi encontrado, ' +
            'entre em contato com suporte'
          )
        });
    },
    toggleValue() {
      this.validadorBotao = !this.validadorBotao;
    }
  }
};
</script>

<style scoped>
.responsive_espacamento{
    margin: 0 15px;
}
.alinhamento_embaixo {
  display: inline-block;
  align-self: flex-end;
}
@media (max-width: 768px) {
    .responsive{
        display:block !important;
        text-align: center;
    }
    .responsive_espacamento{
        margin: 20px 0;
    }
}
</style>
