<template>
  <b-card
    no-body
    class="card-statistics p-1"
  >
    <b-col sm="12" class="mb-2 d-flex align-items-middle corText">
        <a @click="retornarFiltragem">
            <feather-icon icon="ArrowLeftCircleIcon" size="18" class="mr-1" />
            <span class="text">Fazer outra busca</span>
        </a>
    </b-col>
    <b-col sm="12" class="d-flex flex-row">
        <h2 class="mr-1">Painel</h2>
        <a @click.once="buscaDadosRedireciona()"><h2 class="corText">{{this.data.razaoCnpjEmpresa}}</h2></a>
    </b-col>
  </b-card>
</template>

<script>
import { BCard, BCol, BButton, BRow } from 'bootstrap-vue'
import { modalGenericModel } from '@/libs/sweetalerts'

export default {
    components: {
        BCard,
        BCol,
        BButton,
        BRow
    },
    props: {
        data: {
            type: Object,
            default() {
                return {};
            },
            required: true,
        },
    },

    data() {
      return {
        idEmpresaGrupo: ''
      }
    },

    methods: {
        retornarFiltragem()
        {
            this.$emit('redirecionamento-filtragem')
        },
      buscaDadosRedireciona() {
        let rota = this.$api.EmpresasId(this.data.id_empresa);

        this.$http.get(rota).then((res) => {
          const relacionamentoGrupoMatriz = res.data.relacionamento_grupo_matriz
          const relacionamentoGrupoFilial = res.data.relacionamento_grupo_filiais

          if(relacionamentoGrupoMatriz) {
            this.idEmpresaGrupo = relacionamentoGrupoMatriz.id_empresa_grupo
          }

          if(relacionamentoGrupoFilial) {
            this.idEmpresaGrupo = relacionamentoGrupoFilial.id_empresa_grupo
          }

          this.redirecionarParaMatrizOuFilial(
            this.data.id_empresa,
            res.data.id_tipo_empresa,
            this.idEmpresaGrupo
          );
        })
          .catch(() => {
            modalGenericModel(
              'Requisição inválida',
              'A requisição não pôde ser completada, tente novamente mais tarde',
              require('@/assets/custom-icons/cora-icons/warning.png'),
              'Ok'
            )
          });
      },
        redirecionarParaMatrizOuFilial(idEmpresa, idTipoEmpresa, idEmpresaGrupo){
            let rota = '';

            if(idTipoEmpresa === 1) {
                rota = `/grupo/${idEmpresaGrupo}/matriz`;
            }

            if(idTipoEmpresa === 2) {
                rota = `/grupo/${idEmpresaGrupo}/filiais/${idEmpresa}`;
            }

            this.$router.push({ path: rota })
        }
    }
}
</script>

<style scoped>
.corText{
    color: #7030A0;
}
.text{
    vertical-align: middle;
}
@media (max-width:600px) {
  h2 {
    font-size: 18px;
  }
}
</style>
